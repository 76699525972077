<template>
	<div>
		<div class="brand" :class="show">
			<div>Harald Beßner</div>
			<div>Softwareentwickler</div>
		</div>
		<div @click="display = true" class="hbnav-toggle shadow">
			<span class="fas fa-bars"></span>
		</div>
		<div class="hbnav-backdrop" @click="display = false" :class="{display: display}"></div>
		<div class="hbnav-sidebar" :class="{display: display}">
			<NavItem class="" @click.native="setNav(index)" :class="{active: value === index}" v-for="(page, index) in pages" :key="index">
				{{ pageName[page] }}
			</NavItem>
		</div>
	</div>
</template>
<script>

import NavItem from "@/components/nav/NavItem";

export default {
	name: "Nav",
	components: {NavItem},
	props: ["value"],
	methods: {
		setNav: function (value) {
			this.display = false;
				this.$emit("input", value)

		},
		correctIndex(index) {
			if(index == 3) {
				return -1;
			}
			else if(index > 3) {
				return index -1;
			}
			else {
				return index;
			}
		}
	},
	computed: {
		show() {
			return this.value == 0 ? "show" : ""
		}
	},
	data() {
		return {
			display: false,
			pages: [
				"welcome",
				"profile",
				"contact",
				"imprint",
				"privacy"
			],
			pageName: {
				welcome: "Home",
				contact: "Kontakt",
				profile: "Mein Profil",
				imprint: "Impressum",
				privacy: "Datenschutzerklärung",
			}
		}
	}
	
}
</script>
