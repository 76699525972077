<template>
	<div class="navi-page bgDefault">
		<div class="container">
			<PageHeader>Kontakt</PageHeader>
			<div class='content'>
				<div class='row'>
					<div class='col-lg m-2'>
						<div>
							<span class="fas fa-at fa-2x"></span>
						</div>
						<div class="">info@bessner-it.de</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@/components/page/PageHeader";
export default {
	name: "Contact",
	components: {PageHeader}
}
</script>

