<template>
<div class="navi-page bgDefault">
	<div class="container">
		<PageHeader>Mein Profil</PageHeader>
	</div>
	<div class="container">
		<div class="rounded border p-2 border-light mt-2">
			<h3>Ich lebe Softwareentwicklung</h3>
			<ul>
				<li>Mehr als 3 Jahrzehnte IT-Erfahrung</li>
				<li>Mehr als 2 Jahrzehnte Erfahrung als Softwareentwickler</li>
				<li>Mehr als 1 Jahrzehnt Erfahrung als Webentwickler in Vollzeit</li>
				<li>Flexible Arbeitszeitmodelle</li>
				<li>Im Bedarfsfall auch Einsätze am Wochenende oder in der Nacht</li>
				<li>Engagement und Leidenschaft</li>
				<li>Selbstständige und zielorientierte Arbeitsweise</li>
				<li>Gute Umgangsformen und Empathie gegenüber deinen Mitarbeitern und Kunden</li>
			</ul>
		</div>
		<div class="rounded border p-2 border-light mt-2">
			<h3>Ich unterstütze dich mit...</h3>
			<ul>
				<li>Maßgeschneiderten Konzepten</li>
				<li>Individueller Softwareentwicklung</li>
				<li>Anpassung und Revision bestehender Systeme</li>
				<li>Fehlersuche und -beseitigung</li>
				<li>Installation und Aktualisierung von Softwarekomponenten</li>
			</ul>
		</div>
		<div class="rounded border p-2 border-light mt-2">
			<h3>Tech Stack</h3>
			<ul>
				<li>Modernes Softwaredesign und aktuelle Technologien</li>
				<li>Mehrere Jahre Erfahrung: <strong>Javascript, PHP,
					CSS/SCSS, HTML, MySQL</strong> .u.v.a.m</li>
				<li>Versionskontrollsysteme wie <strong>git</strong>, <strong>mercury</strong> und
					<strong>svn</strong></li>
				<li>Reaktives Javascript-Frameworks wie <strong>VueJs</strong>, <strong>react</strong> oder <strong>Angular</strong></li>
				<li>Täglicher Umgang mit <strong>Linuxservern, ssh, bash, (S)FTP, Apache, nginx, Datenbanken, Logfiles, bash, ash, zsh, vim, nano</strong></li>
				<li><strong>Linux</strong>- oder <strong>macOS</strong>-Desktops</li>
				<li><strong>Docker</strong> und <strong>Kubernetes</strong></li>
				<li><strong>Shopware, Symfony, Doctrine, ExtJS,
					Twig</strong> und <strong>Smarty</strong></li>
				<li><strong>Raspberry Pi</strong></li>
			</ul>
		</div>

		<div v-touch:swipe.stop.prevent="" class="jumbotron mt-2 text-center">
		Interessiert? Schick eine E-Mail mit einer kurzen Beschreibung deines Unternehmens und dem, bei dem du Unterstützung brauchst.
		<div class="mt-3 text-info">
			info@bessner-it.de
		</div>
			<h3>Ich in stark, wo du mich brauchst</h3>
		</div>
	</div>
</div>
</template>

<script>
import PageHeader from "@/components/page/PageHeader";
export default {
name: "Profile",
	components: {PageHeader}
}
</script>

<style scoped>
li {
		margin-bottom: 6px;
}
.jumbotron {
	color: #506891 !important;
	font-size: larger;
	font-weight: bold;
}
</style>