<template>
	<div class="navi-page bgDefault">
		<div class="container">
			<PageHeader>Erklärung zum Datenschutz</PageHeader>
			<h2>1. Datenschutz auf einen Blick</h2>
			<h3>Allgemeine Hinweise</h3>
			<p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
				passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
				persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen
				Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.</p>
			<h3>Datenerfassung auf dieser Website</h3>
			<h4>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
			<p>
				Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
				können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
				entnehmen.
			</p>
			<h4>Wie werden Ihre Daten erfasst?</h4>
			<p>
				Es werden generell keinerlei personenbezogene oder sonstige Daten von Ihnen erfasst.
				Diese Seite verfügt über keine Eingabeformulare,
				es werden keine Cookies gespeichert oder sonstige Daten oder Informationen erhoben und gesichert.</p>
			<p>
				Diese Website beruht auf Code, der es ermöglicht, auf technische Gegebenheiten des von Ihnen zur Anzeige
				verwendeten Gerätes zu reagieren, indem z. B. die Schriftgröße angepasst wird. Informationen zu diesen
				Gegebenheiten
				werden zu keiner Zeit erfasst oder gespeichert. Ihre Auswertung dient ausschließlich Ihrem Komfort.
			</p>
			<p>
				Zur Abwehr von Missbrauch dieser Website werden automatische Systeme eingesetzt, die den Datenverkehr überwachen.
				Treten besondere Auffälligkeiten - wie z. B. viele, in sehr kurzer Zeit aufeinander folgende Aufrufe der Website - auf, 
				kann es zu einer Einschränkung des angebotenen Dienstes und zu einer Speicherung allgemeiner Verbindungsdaten kommen. 
				Diese Daten sind jedoch für den Betreiber der Website keiner konkreten Person zuzuordnen.
			</p>
			
			<h4>Wofür werden Ihre Daten genutzt?</h4>
			<p>
				Vom Betreiber dieser Website werden keine Daten erhoben und entsprechend auch keine Daten genutzt, die nicht
				ausschließlich der sicheren und fehlerfreien, bestimmungsgemäßen Bereitstellung dieser Seite dienen. 
				Es kann vom Betreiber der Website kein Zusammenhang zur Person des Nutzers hergestellt werden.
			</p>

			<h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
			<p>Von dieser Seite werden keine personenbezogenen Daten erfasst, ausgewertet oder gespeichert.
				Generell und überall gelten jedoch die nachfolgenden Abschnitte, auch wenn sie in diesem Fall keine
				Anwendung finden:
			</p>
			<p>Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
				gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
				Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
				können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
				bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
				Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.</p>
			<p>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.</p>
			<h4>Analyse-Tools und Tools von Drittanbietern</h4>
			<p>Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor
				allem mit sogenannten Analyseprogrammen.
				Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden
				Datenschutzerklärung.</p>
			<h2>2. Hosting und Content Delivery Networks (CDN)</h2>
			<h4>Externes Hosting</h4>
			<p>Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die
				auf dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich
				v.
				a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten,
				Namen, Websitezugriffe und sonstige Daten, die über eine Website generiert werden, handeln.
				Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und
				bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
				effizienten
				Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f
				DSGVO).
				Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten
				erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.
				Wir setzen folgenden Hoster ein:</p>
			<div class="font-weight-bold">Dienstanbieter im Sinne von § 5 TMG</div>
			<div>checkdomain GmbH</div>
			<div>a dogado group company</div>
			<div>Große Burgstraße 27/29</div>
			<div>23552 Lübeck</div>
			<div class="mb-2">Deutschland</div>
			<h3>3. Allgemeine Hinweise und Pflichtinformationen</h3>
			<h4>Datenschutz</h4>
			<p>Der Betreiber dieser Website erhebt keine personenbezogenen Daten von Ihnen. Der Gesetzgeber verpflichtet ihn jedoch zur Abgabe der folgenden Erlärung</p>
			<i><p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
				personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie
				dieser Datenschutzerklärung.
				Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
			</p>
				<p>Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die
					vorliegende
					Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert
					auch,
					wie
					und zu welchem Zweck das geschieht.
					Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per
					E-Mail)
					Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
					nicht
					möglich.
				</p></i>
			<h4>Hinweis zur verantwortlichen Stelle</h4>
			<p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
			<div>Harald Beßner</div>
			<div>Kranichweg 4</div>
			<div>94315 Straubing</div>
			<div>Telefon: +49 (0) 176 48 77 22 19</div>
			<div class="mb-2">E-Mail: hbessner@googlemail.com</div>
			<p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen
				über
				die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
				entscheidet.</p>
			<h4>Speicherdauer</h4>
			<p>Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben
				Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
				berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen,
				werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung
				Ihrer
				personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
				letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.</p>
			<h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
			<p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
				bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
				Datenverarbeitung bleibt vom Widerruf unberührt.</p>
			<div class="p-1 mb-2 bg-info"><h3>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
				Direktwerbung (Art. 21 DSGVO)</h3>
				<p>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO
					ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
					SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN
					WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
					PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
					ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN,
					WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
					SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
					NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
					VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
					RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
					WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,
					SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
					BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
					EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
					VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
					ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
					NACH ART. 21 ABS. 2 DSGVO).</p></div>
			<h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
			<p>Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
				Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
				oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
				verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p>
			<h4>Recht auf Datenübertragbarkeit</h4>
				<p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
				automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
				aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
				verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
			<h4>SSL- bzw. TLS-Verschlüsselung</h4>
			<p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
				Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
				TLSVerschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers
				von
				„http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
				Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln,
				nicht
				von Dritten mitgelesen werden.</p>
			<h4>Auskunft, Löschung und Berichtigung</h4>
			<p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
				Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den
				Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie
				zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.</p>
			<h4>Recht auf Einschränkung der Verarbeitung</h4>
			<p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
				Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
				folgenden Fällen:</p>
			<p>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir
				in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
				Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</p>
			<p>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie
				statt der Löschung die Einschränkung der Datenverarbeitung verlangen.</p>
			<p>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
				Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der
				Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</p>
			<p>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen
				Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
				überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
				zu verlangen.</p>
			<p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von
				ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
				Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
				juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder
				eines Mitgliedstaats verarbeitet werden.</p>
			<strong>Quelle:
				e-recht24.de</strong>
		</div>
	</div>
</template>

<script>
import PageHeader from "@/components/page/PageHeader";
export default {
	name: "Privacy",
	components: {PageHeader}
}
</script>
