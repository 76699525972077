import Vue from 'vue'
import App from './App.vue'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
Vue.config.productionTip = false
import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(Vue2TouchEvents)

new Vue({
  render: h => h(App),
}).$mount('#app')
