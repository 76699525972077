<template>
	<div class="navi-page">
		
		
		<img src="../assets/Logo.nbg.white.png" class="its-bessner-logo"/>
		<div class="hb_bg hb_bg_1" :class="getTickClass(0)"></div>
		<div class="hb_bg hb_bg_2" :class="getTickClass(1)"></div>
		<div class="hb_bg hb_bg_3" :class="getTickClass(2)"></div>
		<div class="container">
			<Runner>
				PHP
				MySQL
				CSS
				HTML
				Javascript
				Docker
				git
				SASS
				scss
				react
				VueJS
				bash
				OOP
				MVC
				MVVM
				responsive
				reactive
				API
				Go
				Python
				Twig
				Apache
				nginx
				npm
				node
				JSON
				YAML
				REST
				OpenAPI
				SQLite
				Kubernetes
				</Runner>
		</div>
	</div>
</template>

<script>
import Runner from "@/components/welcome/Runner";
export default {
	name: "Welcome",
	props: ["value"],
	components: { Runner},
	data() {
		return {
			ticker: 0
		}
	},
	mounted() {
		this.tick()
	},
	methods: {
		openCheck() {
			this.$emit("input", 1);
		},
		tick() {
			const me = this;
			this.ticker++;
			this.ticker %= 18;
			setTimeout(me.tick, 2000)
		},
		getTickClass(index) {
			
			return [
				[1, 0, 1],
				[1, 0, 0],
				[1, 0, 0],
				[1, 0, 0],
				[1, 0, 0],
				[1, 0, 0],
				[1, 1, 0],
				[0, 1, 0],
				[0, 1, 0],
				[0, 1, 0],
				[0, 1, 0],
				[0, 1, 0],
				[0, 1, 1],
				[0, 0, 1],
				[0, 0, 1],
				[0, 0, 1],
				[0, 0, 1],
				[0, 0, 1],
			][this.ticker][index] ? "" : "hb-hidden";
			
			
			
		}
		
		
	}
}
</script>
<style>

</style>
