<template>
	<div id="app" v-touch:swipe="swipe" :class="'navi_' + page">
		<Nav v-model="page"></Nav>
		<Welcome v-model="page" class="navi-0"/>
		<Profile class="navi-1"/>
		<Contact class="navi-2"/>
		<Imprint class="navi-3"/>
		<Privacy class="navi-4"/>
	</div>
</template>

<script>


import Welcome from "@/components/Welcome";
import Nav from "@/components/Nav";
import Imprint from "@/components/Imprint";
import Privacy from "@/components/Privacy";
import Contact from "@/components/Contact";
import "@01harrie/tools";
import Profile from "@/components/Profile";
export default {
	name: 'App',
	components: {
		Profile,
		Contact,
		Privacy,
		Imprint,
		Nav,
		Welcome,
	},
	data() {
		return {
			page: 0
		}
	},
	methods: {
		swipe(direction) {
			switch(direction) {
				case "left":
					this.setNav(this.page + 1)
					break;
				case "right":
					this.setNav(this.page - 1)
					break;
			}
		},
		setNav(page) {
			
			if(page < 0) {
				return;
			}
			if(page > 4) {
				return
			}
			this.page = page;
		}
	}
}
</script>

<style lang="scss">

@import "~bootstrap/scss/bootstrap";
@import "./st/app";


#app {

}
</style>
