<template>
	<div class="navi-page bgDefault">
		<div class="container">
			<PageHeader>Impressum</PageHeader>
			<div class="content">
				<h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
				<p>Harald Beßner<br/>
					Kranichweg 4<br/>
					94315 Straubing</p>
				
				<h2>Kontakt</h2>
				<p>Telefon: +49 176 48 77 22 19<br/>
					E-Mail: info@bessner-it.de</p>
				
				<p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@/components/page/PageHeader";
export default {
	name: "Imprint",
	components: {PageHeader}
}
</script>
