<template>
	<div class="runner">
		<div v-for="(skill, index) in skills" :key="index" :class="'runner v_' + index + ' s_' + spindex[index] + ' d_' + drindex[index]">{{ skill }}</div>
	</div>
</template>
<script>
export default {
	name: "Runner",
	methods: {
		ar() {
			let ar = [];
			for(let i = 0; i < 40; i++) {
				ar.push(i);
			}
			return ar;
		},
	},
	
	computed: {
		skills() {
			return this.$slots.default[0].text.split(/\s/).filter(a => {
				return a
			}).shuffle();
		},

		drindex() {
			return this.ar().shuffle();
		},

		spindex() {
			return this.ar().shuffle();
		}
	}
}
</script>
<style lang="scss" scoped>


</style>