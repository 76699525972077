if(!Array.prototype.shuffle) {
	Array.prototype.shuffle = function() {
		for(let i = 0, l = this.length; i < l; i++) {
			let proxy = this.sort(() => {
				return ((Math.random() * 3) << 1) - 2;
			})
			this.splice(0, this.length, ...proxy);
		}
		return this;
	}
}


export default {}