<template>
	<div class="hbnav-item">
		<slot/>
	</div>
</template>
<script>
export default {
	name: "NavItem",
	methods: {
		alert() {
			window.alert("23")
		}
	}
}
</script>
